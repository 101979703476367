import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"
import { SiteContext } from "@walltowall/p3r-races"
import SVGInject from "@iconfu/svg-inject"
import { yellow, blue, branding } from "../colors"

import { Collapse } from "@material-ui/core"
import arrowDown from "../images/arrow_down.svg"
import { SearchIcon } from "@walltowall/p3r-races"
import hoverintent from "hoverintent"
// import yearlyLogo from '../images/2024_Marathon_Logo.png'

const HeaderContainer = styled.header`
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 5;
`

const HeaderInner = styled.div`
  background-color: white;
  padding: 25px 16px 20px;
  position: relative;
  min-height: 45px;

  @media screen and (min-width: 600px) {
    padding: 65px 20px 20px;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px 0 332px;
    min-height: unset;
  }

  @media screen and (min-width: 1120px) {
    padding-left: 440px;
  }

  > img {
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  > nav {
    height: 0;
    overflow: hidden;

    @media screen and (min-width: 1024px) {
      height: auto;
      overflow: visible;
    }
  }

  > div[class*="MuiCollapse"] {
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
`

const HeaderLogo = styled(Link)`
  display: block;
  position: absolute;
  top: -27px;
  left: 16px;

  @media screen and (min-width: 600px) {
    top: -42px;
    left: 28px;
  }

  @media screen and (min-width: 1024px) {
    top: -28px;
  }

  @media screen and (min-width: 1120px) {
    top: -40px;
    left: 48px;
  }

  & img {
    width: 57px;
    height: 58px;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 600px) {
      width: 116px;
      height: 108px;
    }

    @media screen and (min-width: 1120px) {
      width: 140px;
      height: 130px;
    }

    &.logo-large {
      display: none;

      @media screen and (min-width: 768px) {
        display: block;
        width: 220px;
        height: auto;
        top: 4px;
        left: 117px;
        position: absolute;
        max-width: unset;
      }
      @media screen and (min-width: 1020px) {
        width: 273px;
        top: 2px;
        left: 142px;
      }
    }
  }

  & div {
    display: none;

    @media screen and (min-width: 600px) {
      display: inline-block;
      background: rgb(189, 98, 40);
      background: linear-gradient(
        45deg,
        rgba(189, 98, 40, 1) 0%,
        rgba(252, 195, 58, 1) 25%,
        rgba(254, 237, 196, 1) 65%,
        rgba(252, 195, 58, 1) 84%,
        rgba(189, 98, 40, 1) 100%
      );
      position: absolute;
      transform: skew(-30.5deg);
      display: flex;
      align-items: center;
      width: 211px;
      height: 97px;
      top: 11px;
      left: 86px;
      padding-left: 34px;
    }

    @media screen and (min-width: 1120px) {
      width: 237px;
      height: 116px;
      top: 13px;
      left: 105px;
    }

    & span {
      transform: skew(30.5deg);
      font-size: 28px;
      font-size: 1.75rem;
      font-weight: 800;
      position: relative;
      top: -3px;
      color: black;

      @media screen and (min-width: 600px) {
        font-size: 66px;
        font-size: 4.125rem;
        top: -5px;
      }

      @media screen and (min-width: 1120px) {
        font-size: 78px;
        font-size: 4.875rem;
      }
    }
  }
`

const MainMenu = styled.nav`
  > ul > li {
    &.has-children {
      border-bottom: 1px solid #979797;
      padding: 10px 0 0;

      @media screen and (min-width: 1024px) {
        border-bottom: 0;
        padding-top: 0;

        button {
          transition: transform 0.3s ease;
          display: inline-block;
          transform: rotate(0deg);
        }

        &:hover {
          background: ${blue};

          button {
            transform: rotate(180deg);
          }
        }
      }
    }

    > a {
      width: calc(100% - 26px);
      display: inline-block;
      padding-top: 8px;

      @media screen and (min-width: 600px) {
        width: calc(100% - 39px);
      }

      @media screen and (min-width: 1024px) {
        width: auto;
        padding: 35px 30px;

        &:hover {
          background: ${blue};
          color: black;
        }
      }
    }
  }

  & > ul > li.button {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-87%);

    @media screen and (min-width: 600px) {
      top: 27px;
      left: unset;
      right: 78px;
    }

    @media screen and (min-width: 768px) {
      right: 98px;
    }

    @media screen and (min-width: 1024px) {
      position: static;
      transform: translateX(0);
      display: flex;
      align-items: center;
      postion: static;
    }

    + .button {
      transform: translateX(14%);

      @media screen and (min-width: 1024px) {
        transform: translateX(0);
      }

      > a {
        background: transparent;
        position: relative;
        border-bottom: 4px solid ${yellow};
        transition: border 0.2s ease;
        border-radius: 0;
        padding-left: 0;
        padding-bottom: 3px;
        padding-right: 0;
        margin-left: 16px;
        margin-right: 16px;

        @media screen and (min-width: 1024px) {
          padding-bottom: 8px;
        }

        &:hover {
          background: transparent;
          color: black;
          border-color: black;
        }
      }
    }

    > a {
      background-color: ${yellow};
      display: inline-block;
      font-size: 12px;
      font-size: 0.75rem;
      font-weight: 800;
      font-family: inherit;
      text-transform: uppercase;
      text-decoration: none;
      padding: 5px 13px 7px;
      border-radius: 4px;
      transition: background-color 0.21s ease-out;
      width: auto;

      @media screen and (min-width: 768px) {
        font-size: 15px;
        font-size: 0.9375rem;
      }

      @media screen and (min-width: 1024px) {
        margin: -4px 16px 12px;
        padding: 10px 16px 12px;
      }

      @media screen and (min-width: 1250px) {
        margin-top: 5px;
        padding: 10px 40px 12px;

        font-size: 20px;
        font-size: 1.25rem;
      }

      &:hover,
      &:focus {
        background-color: #555;
      }
    }
  }

  & ul {
    margin: 0;
    padding: 30px 0 0;
    list-style: none;

    @media screen and (min-width: 1120px) {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  & a {
    display: block;
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 800;
    text-decoration: none;
    color: black;
    padding: 0 16px 8px 0;
    transition: color 0.21s ease-out;

    @media screen and (min-width: 600px) {
      font-size: 32px;
      font-size: 2rem;
      padding: 0 16px 8px;
    }

    @media screen and (min-width: 1024px) {
      font-size: 24px;
      font-size: 1.5rem;
    }

    @media screen and (min-width: 1250px) {
      font-size: 32px;
      font-size: 2rem;
      padding: 0 24px 12px;
    }

    &:hover,
    &:focus {
      color: ${blue};
    }

    + button {
      appearance: none;
      border: 0;
      padding: 0;
      background: none;
      transition: opacity 0.21s ease-out;
      width: 24px;
      height: 24px;
      padding: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;

      @media screen and (min-width: 600px) {
        width: 32px;
        height: 32px;
        padding: 4px;
      }

      &:hover,
      &:focus {
        opacity: 0.65;
      }

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: url(${arrowDown}) center no-repeat;
        background-size: contain;
      }

      @media screen and (min-width: 1024px) {
        position: relative;
        left: -18px;
        top: 3px;
      }
    }
  }

  & ul ul {
    background: ${blue};
    width: calc(100% + 32px);
    flex-wrap: wrap;
    display: none;
    height: 0;
    transition: height 0.4s;
    overflow: hidden;
    margin: 0 -16px;
    padding: 16px;
    position: relative;
    bottom: -2px;

    @media screen and (min-width: 600px) {
      width: calc(100% + 40px);
      margin: 0 -20px;
      padding: 0 20px 0 36px;
    }

    @media screen and (min-width: 1024px) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;

      position: absolute;
      top: 100%;
      left: -99999px;
      margin: 0;
      padding: 40px;
      box-shadow: 0 14px 16px rgba(0, 0, 0, 0.2);
      border-top: 4px solid ${blue};
      transition: opacity 0.21s ease-out, left 0s 0.21s;
      opacity: 0;

      .race {
        margin-bottom: 0;

        &:nth-child(odd) {
          grid-column: 1;
        }

        &:nth-child(even) {
          grid-column: 2;
        }
      }

      &.races-subnav {
        li:not(.race) {
          margin-bottom: 0;
          grid-column: 3;

          > a {
            font-size: 18px;
            font-size: 1.125rem;
            border-left: 2px solid #9ec3ec;
          }
        }
      }

      &.programs-subnav {
        grid-template-columns: 1fr 1fr 1.5fr;
      }

      &:not(.has-promo) {
        height: 428px !important;
      }

      &.show {
        left: 0;
        opacity: 1;
        transition: opacity 0.21s ease-out;
      }
    }

    @media screen and (min-width: 1260px) {
      padding: 42px 7vw;

      &:not(.has-promo) {
        height: 410px !important;
      }
    }

    li {
      width: 100%;
      flex: 0 0 auto;
      margin-bottom: 12px;

      @media screen and (min-width: 600px) {
        width: auto;
        margin-bottom: 32px;
      }

      &:not(.race-weekend):first-child {
        padding-top: 10px;

        @media screen and (min-width: 1024px) {
          padding-top: 0;
        }
      }

      &.race {
        + li:not(.race) {
          border-top: 2px solid #9ec3ec;
          padding-top: 16px;

          @media screen and (min-width: 1024px) {
            grid-row: 1;
            border-top: 0;
            padding-top: 0;

            + li {
              grid-row: 2;
              + li {
                grid-row: 3;
                + li {
                  grid-row: 4;
                  + li {
                    grid-column: 4;
                    grid-row: 1;
                    + li {
                      grid-column: 4;
                      grid-row: 2;

                      + li {
                        grid-column: 4;
                        grid-row: 3;

                        + li {
                          grid-column: 4;
                          grid-row: 4;
                        }

                        + li {
                          grid-column: 4;
                          grid-row: 5;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        @media screen and (min-width: 600px) {
          width: 100%;
        }

        @media screen and (min-width: 1024px) {
          width: 100%;
          min-width: 172px;
        }
      }

      &:not(.race) {
        margin-bottom: 10px;
        align-self: flex-start;

        @media screen and (max-width: 1023px) {
          &:not(.race-weekend) {
            width: 100%;
          }
        }

        @media screen and (min-width: 1024px) {
          margin-bottom: 0;

          > a {
            padding: 17px 19px 36px 4%;
          }
        }

        @media screen and (min-width: 1260px) {
          margin-bottom: 0;

          > a {
            padding: 17px 19px 36px 36px;
          }
        }
      }

      a {
        display: block;
        padding-left: 0;
        padding-right: 32px;
        font-size: 16px;
        font-size: 1rem;

        @media screen and (min-width: 600px) {
          font-size: 18px;
          font-size: 1.25rem;
          padding-right: 40px;
        }

        @media screen and (min-width: 1024px) {
          font-size: 26px;
          font-size: 1.625rem;
          padding-right: 35px;
        }

        &:hover,
        &:focus {
          color: white;

          .badge {
            background-color: black;

            svg * {
              fill: ${yellow};
            }
          }
        }
      }
    }

    &.has-promo {
      @media screen and (min-width: 1024px) {
        padding-left: 524px;
      }
    }

    .race-weekend {
      width: calc(100% - 36px);
      flex: 0 0 auto;
      order: 0;
      margin-right: auto;
      background-color: ${branding};
      margin-left: -36px;
      padding: 14px 0 14px 36px;
      position: relative;

      @media screen and (min-width: 600px) {
        width: 500px;
      }

      @media screen and (min-width: 1024px) {
        width: 328px;
        padding: 62px 0 62px 48px;
        position: absolute;
        top: 0;
        left: 36px;
      }

      &::before {
        content: "";
        background-color: ${branding};
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: skew(-30.5deg);
        transform-origin: bottom left;
      }

      > * {
        position: relative;
        margin-right: -16px;
      }

      a {
        color: white;
        font-size: 32px;
        font-size: 2rem;
        transition: opacity 0.21s ease-out;
        padding-right: 0;

        @media screen and (max-width: 599px) {
          font-size: 24px;
          font-size: 1.5rem;
        }

        + button {
          display: none;
        }

        &:hover,
        &:focus {
          opacity: 0.7;
        }
      }

      ul {
        top: 0;
        left: 0;
        border-top: 0;
        padding: 0;
        background: none;
        display: block;
        box-shadow: none;
        margin-top: 10px;
        display: block !important;
        height: auto !important;
        margin-left: 0;

        li {
          width: 100%;
          margin-bottom: 10px;
        }

        a {
          font-size: 16px;
          font-size: 1rem;

          @media screen and (min-width: 600px) {
            font-size: 20px;
            font-size: 1.25rem;
          }

          @media screen and (min-width: 1024px) {
            font-size: 26px;
            font-size: 1.625rem;
          }
        }
      }
    }
  }

  .badge {
    position: relative;
    background-color: ${yellow};
    transform: skew(-30.5deg);
    transform-origin: bottom left;
    overflow: hidden;
    transition: background-color 0.21s ease-out;
    margin-bottom: 6px;

    @media screen and (min-width: 600px) {
      margin-bottom: 18px;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 49.3%;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: skew(30.5deg);
      object-fit: cover;
      overflow: visible;

      * {
        transition: fill 0.21s ease-out;
      }
    }
  }
`

const SubMenu = styled.nav`
  margin-top: 17px;

  @media screen and (min-width: 1024px) {
    display: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    ul {
      display: none;
    }
  }

  & a {
    display: block;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    color: black;
    padding: 0 16px 11px 0;
    transition: color 0.21s ease-out;
    text-transform: uppercase;

    @media screen and (min-width: 600px) {
      font-size: 20px;
      font-size: 1.25rem;
      padding: 0 16px 11px;
    }

    &:hover,
    &:focus {
      color: ${blue};
    }

    + button {
      display: none;
    }
  }
`

const MenuToggle = styled.button`
  position: absolute;
  top: 8px;
  right: 16px;
  width: 30px;
  height: 30px;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: none;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  @media screen and (min-width: 600px) {
    top: 27px;
    right: 28px;
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }

  &:hover,
  &:focus {
    opacity: 0.65;
  }

  span {
    width: 30px;
    height: 4px;
    border-radius: 4px;
    background-color: black;
    position: absolute;
    left: 0;

    &:nth-child(1) {
      top: 5px;
      transition: top 0.2s 0.2s ease-in-out, transform 0.2s ease-in-out;
    }

    &:nth-child(2) {
      top: 13px;
      transition: opacity 0s 0.2s;
    }

    &:nth-child(3) {
      top: 21px;
      transition: top 0.2s 0.2s ease-in-out, transform 0.2s ease-in-out;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        top: 13px;
        transform: rotate(45deg);
        transition: top 0.2s ease-in-out, transform 0.2s 0.2s ease-in-out;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        top: 13px;
        transform: rotate(-45deg);
        transition: top 0.2s ease-in-out, transform 0.2s 0.2s ease-in-out;
      }
    }
  }
`

const Search = styled.div`
  padding: 10px 16px 0;
  height: 54px;
  position: relative;

  @media screen and (min-width: 600px) {
    padding: 10px 16px 0;
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }

  > button {
    appearance: none;
    background: none;
    border: 0;
    padding: 0;
    position: absolute;
    top: calc(50% + 10px);
    left: 0;
    transform: translateY(-50%);
    cursor: pointer;

    @media screen and (min-width: 600px) {
      left: 16px;
    }

    svg {
      width: 30px;
      height: 26px;

      path {
        fill: black;
        transition: fill 0.21s ease-out;
      }
    }

    &:hover,
    &:focus {
      svg path {
        ${(props) => `
          fill: ${props.hover}
        `}
      }
    }
  }

  form {
    overflow: hidden;
    transition: width 0.3s ease-out;
    display: flex;
    flex-wrap: nowrap;
    margin: 0;

    input,
    button {
      height: 44px;
    }

    input {
      background: none;
      border: 2px solid #dfdfdf;
    }

    button {
      padding: 0 14px 2px;
      margin-left: -5px;
      ${(props) => `
        background-color: ${yellow}
      `}
    }
  }
`

const MarathonHeader = (props) => {
  const context = useContext(SiteContext)
  const [open, setOpen] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const { menus } = props

  useEffect(() => {
    context.getSite("pittsburgh-marathon")

    if (document.querySelectorAll("img.injectable").length) {
      SVGInject(document.querySelectorAll("img.injectable"), {
        afterLoad: (svg) => {
          if (svg) {
            svg.setAttribute("preserveAspectRatio", "xMidYMid slice")
          }
        },
      })
    }

    const topLevel = document.querySelectorAll(".primary-nav > li > a")

    Array.from(topLevel).forEach((link) => {
      hoverintent(
        link,
        () => {
          hideSubNavs()
          const childNav = Array.from(link.parentNode.children).find(
            (el) => el.tagName === "UL",
          )
          if (childNav) {
            childNav.classList.add("show")
          }
        },
        () => {},
      )
    })

    document.querySelector('[class*="SubHeader"]').onmouseover = () => {
      hideSubNavs()
    }

    const outsideElements = document.querySelectorAll("main, section, footer")
    Array.from(outsideElements).forEach((el) => {
      el.onmouseover = () => {
        hideSubNavs()
      }
    })

    const childrenToggles = document.querySelectorAll(".primary-nav a + button")
    Array.from(childrenToggles).forEach((button) => {
      button.addEventListener("click", showChildNav)
    })

    const raceWeekend = document.querySelector(".race-weekend")

    if (raceWeekend) {
      const raceWeekendParent = raceWeekend.parentElement
      raceWeekendParent.classList.add("has-promo")

      document.addEventListener(
        "ready",
        setRaceWeekendHeight(raceWeekendParent, raceWeekend),
      )
      document.addEventListener(
        "resize",
        setRaceWeekendHeight(raceWeekendParent, raceWeekend),
      )
    }

    return () => {
      Array.from(childrenToggles).forEach((button) => {
        button.removeEventListener("click", showChildNav)
      })

      if (raceWeekend) {
        document.removeEventListener("ready", setRaceWeekendHeight)
        document.removeEventListener("resize", setRaceWeekendHeight)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showChildNav = (event) => {
    const target = event.currentTarget
    slideToggle(target.nextSibling)
  }

  const setRaceWeekendHeight = (parent, promo) => {
    if (parent) {
      parent.style.height = "auto"

      const defaultHeight = parent.offsetHeight
      const promoHeight = promo.offsetHeight

      const newHeight =
        defaultHeight > promoHeight ? defaultHeight : promoHeight
      parent.style.height = `${newHeight}px`
    }
  }

  const slideToggle = (target) => {
    if (target.offsetHeight === 0) {
      target.style.display = "flex"
      target.style.height = `${target.scrollHeight}px`
      target.previousSibling.style.transform = "rotate(180deg)"
    } else {
      target.style.height = 0
      target.previousSibling.style.transform = "rotate(0deg)"

      setTimeout(() => {
        target.style.display = "none"
      }, 400)
    }
  }

  const hideSubNavs = () => {
    const allChildNavs = document.querySelectorAll("header li.has-children ul")
    Array.from(allChildNavs).forEach((el) => el.classList.remove("show"))
  }

  return (
    <HeaderContainer>
      <HeaderInner>
        <HeaderLogo to="/">
          {context.site && (
            <img
              className="logo-small"
              src={`${context.root}${context.site.logo}`}
              alt="Pittsburgh Marathon logo"
            />
          )}
          {/* {context.site && (
            <img
              className='logo-large'
              src={`${yearlyLogo}`}
              alt='2024'
            />
          )} */}

          {/* <div>
            <span>2023</span>
          </div> */}
        </HeaderLogo>

        <MenuToggle
          onClick={() => setOpen(!open)}
          className={open ? "open" : ""}
        >
          <span></span>
          <span></span>
          <span></span>
        </MenuToggle>

        {(menus || {}).main && (
          <React.Fragment>
            <MainMenu
              dangerouslySetInnerHTML={{
                __html: menus.main,
              }}
            />

            <Collapse in={open} className="mobile-menu">
              <MainMenu
                dangerouslySetInnerHTML={{
                  __html: menus.main,
                }}
              />

              <SubMenu
                dangerouslySetInnerHTML={{
                  __html: menus.sub,
                }}
              />

              <Search button={yellow} hover={blue}>
                <button
                  onClick={() => setShowSearch(true)}
                  style={{
                    opacity: showSearch ? 0 : 1,
                    pointerEvents: showSearch ? "none" : "auto",
                  }}
                >
                  <SearchIcon />
                </button>

                <form
                  action="/search"
                  method="get"
                  style={{
                    width: showSearch ? "100%" : "0%",
                  }}
                >
                  <input type="text" name="query" />

                  <button>Search</button>
                </form>
              </Search>
            </Collapse>
          </React.Fragment>
        )}
      </HeaderInner>
    </HeaderContainer>
  )
}

export default MarathonHeader
