import React, { useEffect, useState } from "react"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom"
import {
  Events,
  Event,
  Homepage,
  Page,
  Races,
  Race,
  News,
  Post,
  Search,
  SiteProvider,
  SubHeader,
} from "@walltowall/p3r-races"
import { blue, branding, yellow, button, newsEvents } from "./colors"
import MarathonHeader from "./components/MarathonHeader"
import MarathonFooter from "./components/MarathonFooter"
import Error404 from "./components/Error404"
import moveM from "./images/move_m.svg"
import "swiper/css/swiper.css"
import "@fullcalendar/core/main.css"
import "@fullcalendar/daygrid/main.css"
import runner from "./images/Runner_Female_A_v1.svg"

function App() {
  const [menus, setMenus] = useState(null)
  const root =
    process.env.NODE_ENV === "development"
      ? "http://localhost:4242"
      : "https://p3r.org"

  useEffect(() => {
    fetch(`${root}/sites/marathon_menus.json`)
      .then((response) => response.json())
      .then((data) => {
        setMenus(data.menus)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router>
      <SiteProvider root={root}>
        {menus && (
          <>
            <SubHeader menus={menus} />

            <MarathonHeader menus={menus} />
          </>
        )}

        <Switch>
          <Route exact path={["/", "/pages/home-pittsburgh-marathon"]}>
            <Homepage
              colors={{
                primary: yellow,
                secondary: blue,
                tertiary: branding,
                button,
                newsEvents,
                darkText: true,
              }}
              site='pittsburgh-marathon'
            />
          </Route>

          <Route exact path='/events'>
            <Events site='pittsburgh-marathon' color={blue} />
          </Route>

          <Route path='/events/:id'>
            <Event
              colors={{
                primary: yellow,
                secondary: blue,
                button,
              }}
              fallback={moveM}
            />
          </Route>

          <Route exact path='/races'>
            <Races
              pageId='races-pittsburgh-marathon'
              site='pittsburgh-marathon'
              colors={{
                primary: yellow,
                secondary: blue,
                tertiary: branding,
                button,
                darkText: true,
              }}
            />
          </Route>

          <Route path='/pages/:id'>
            <Page
              site='pittsburgh-marathon'
              colors={{
                primary: yellow,
                secondary: blue,
                tertiary: branding,
                button,
                darkText: true,
              }}
              mapboxToken='pk.eyJ1IjoiYnJvd250b3duNDEyIiwiYSI6ImNpamRheHZyejAwNjJ2aG0wa2JyM3U4bW0ifQ.etQxVe143jcNlm9ocMBA6w'
            />
          </Route>

          <Route path='/races/:id'>
            <Race
              site='pittsburgh-marathon'
              colors={{
                primary: yellow,
                secondary: blue,
                tertiary: branding,
                button,
                darkText: true,
              }}
            />
          </Route>

          <Route exact path='/blog'>
            <News
              type='blog'
              colors={{
                primary: yellow,
                secondary: blue,
                tertiary: branding,
                button,
                darkText: true,
              }}
            />
          </Route>

          <Route exact path='/news'>
            <News
              type='news'
              colors={{
                primary: yellow,
                secondary: blue,
                tertiary: branding,
                button,
                darkText: true,
              }}
            />
          </Route>

          <Route path='/blog/:id'>
            <Post
              type='blog'
              colors={{
                primary: yellow,
                secondary: blue,
                tertiary: branding,
                button,
                darkText: true,
              }}
              icon={runner}
            />
          </Route>

          <Route path='/news/:id'>
            <Post
              type='news'
              colors={{
                primary: yellow,
                secondary: blue,
                tertiary: branding,
                button,
                darkText: true,
              }}
              icon={runner}
            />
          </Route>

          <Route path='/search'>
            <Search
              colors={{
                primary: yellow,
                secondary: blue,
              }}
            />
          </Route>

          <Route path='/404'>
            <Error404 />
          </Route>

          <Route>
            <Redirect to='/404' />
          </Route>
        </Switch>

        <MarathonFooter menus={menus} />
      </SiteProvider>
    </Router>
  )
}

export default App
