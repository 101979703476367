import React, { useContext } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import { DateTime } from 'luxon'
import { RacesCard, RacesCardDetails } from './RacesCards'

const RacesListContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 48px;
  padding: 32px 21px;

  @media screen and (min-width: 500px) {
    padding: 46px 8.3% 100px;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 80px 112px;
  }

  @media screen and (min-width: 1024px) {
    grid-gap: 80px 156px;
  }

  a {
    width: calc(100% - 64px);

    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
`

const RacesList = (props) => {
  const context = useContext(SiteContext)
  const { block, colors, site } = props
  const { races } = block

  return (
    <RacesListContainer>
      {(races || []).map((item, index) => {
        const race = item.object.race
        const image = item.featured
        const details = (item.details || {}).block
        const logo = item.logo

        return (
          <RacesCard key={index} href={`/races/${race.slug}`} site={site}>
            <figure>
              {image && (
                <React.Fragment>
                  <img
                    src={`${context.root}${image.block.settings.image.image}`}
                    alt={image.block.settings.alt_attribute_description}
                  />
                </React.Fragment>
              )}
            </figure>

            <RacesCardDetails background={colors.primary}>
              {logo && <img src={`${context.root}${logo}`} />}

              <div>
                <h3>{race.title}</h3>

                {details && (
                  <React.Fragment>
                    {details.settings.date && (
                      <React.Fragment>
                        <p>
                          {DateTime.fromFormat(
                            details.settings.date,
                            'MMMM d, yyyy h:mm a'
                          ).toFormat('EEE, LLLL d, yyyy')}
                        </p>

                        <p>
                          {`START @ ${DateTime.fromFormat(
                            details.settings.date,
                            'MMMM d, yyyy h:mm a'
                          ).toFormat('h:mma')}`}
                        </p>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
            </RacesCardDetails>
          </RacesCard>
        )
      })}
    </RacesListContainer>
  )
}

export default RacesList
