import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Block from '../../Block'
import Columns, { LeftColumn, RightColumn } from '../../columns'
import SiteContext from '../../../state/context'

const Race = (props) => {
  const context = useContext(SiteContext)
  const historyParams = useParams()
  const { site } = props
  const {
    primary,
    secondary,
    tertiary,
    primaryText,
    accordionBackground,
    button,
    darkText,
  } = props.colors

  useEffect(() => {
    context.getRace(historyParams.id, props.site || 'pittsburgh-marathon')
  }, [])

  return (
    <main>
      {Object.keys(context.race).length > 0 && (
        <React.Fragment>
          {context.race.blocks['Hero'] &&
            context.race.blocks['Hero'].map((block) => (
              <Block
                key={block.id}
                data={block}
                colors={{
                  primary,
                  secondary,
                  tertiary,
                  button,
                  darkText,
                }}
              />
            ))}

          <Columns>
            <LeftColumn>
              {context.race.blocks['Left Column'] &&
                context.race.blocks['Left Column'].map((block) => (
                  <Block
                    key={block.id}
                    data={block}
                    colors={{
                      primary,
                      secondary,
                      tertiary,
                      button,
                      darkText,
                    }}
                  />
                ))}
            </LeftColumn>

            <RightColumn>
              {context.race.blocks['Right Column'] &&
                context.race.blocks['Right Column'].map((block) => (
                  <Block
                    key={block.id}
                    data={block}
                    site={site}
                    colors={{
                      primary,
                      secondary,
                      tertiary,
                      button,
                      darkText,
                    }}
                  />
                ))}
            </RightColumn>
          </Columns>

          {context.race.blocks['Above Footer'] &&
            context.race.blocks['Above Footer'].map((block) => (
              <Block
                key={block.id}
                data={block}
                site={site}
                colors={{
                  primary,
                  secondary,
                  tertiary,
                  primaryText,
                  accordionBackground,
                  button,
                  darkText,
                }}
              />
            ))}
        </React.Fragment>
      )}
    </main>
  )
}

export default Race
