import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"
import medal from "../images/Logo_Gold_v1_Black_Matte_No_Dither.gif"
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "@walltowall/p3r-races"

const FooterContainer = styled.footer`
  background-color: black;
  padding: 48px 21px 106px;
  color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: 500px) {
    padding: 48px 8.3% 148px;
  }

  @media screen and (min-width: 800px) {
    flex-direction: row;
    padding-bottom: 168px;
  }

  > svg {
    display: none;

    @media screen and (min-width: 1200px) {
      opacity: 0.15;
      width: 932px;
      display: block;
      position: absolute;
      bottom: -36px;
      left: -67px;
    }
  }

  & > div {
    position: relative;

    &:first-of-type {
      width: 50%;
      flex: 0 0 auto;

      @media screen and (min-width: 1200px) {
        width: 38%;
      }

      @media screen and (min-width: 1350px) {
        width: 40%;
      }

      > img {
        width: 250px;

        @media screen and (min-width: 1200px) {
          width: auto;
        }
      }
    }

    &:last-of-type {
      flex: 1 1 auto;
    }
  }

  nav.footer-menu {
    > ul {
      column-count: 2;
      column-gap: 34px;

      @media screen and (min-width: 500px) {
        column-gap: 60px;
      }

      @media screen and (min-width: 1200px) {
        column-count: 3;
        column-gap: 72px;
      }
    }
  }

  nav {
    margin-bottom: 28px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    ul {
      margin-bottom: 26px;
    }
  }

  li {
    break-inside: avoid;
  }

  a {
    display: block;
    text-decoration: none;
    font-size: 14px;
    font-size: 0.875rem;
    color: white;
    font-weight: 400;
    margin-bottom: 2px;
    transition: opacity 0.21s ease-out;

    @media screen and (min-width: 500px) {
      font-size: 18px;
      font-size: 1.125rem;
    }

    &:hover,
    &:focus {
      opacity: 0.65;
    }
  }

  nav > ul {
    > li > a {
      font-weight: 800;
      color: #bdbdbd;
    }
  }
`

const Social = styled.div`
  margin-top: 38px;
  margin-bottom: 28px;

  @media screen and (min-width: 800px) {
    margin-top: 0;
    margin-left: 0;
    padding-left: calc(50% + 30px);
  }

  @media screen and (min-width: 1200px) {
    padding-left: calc(66.6665% + 48px);
  }

  nav {
    margin-bottom: 20px;

    li {
      margin-bottom: 10px;
    }
  }

  .social-icon {
    width: 38px;
    height: 38px;
    background-color: white;
    border-radius: 50%;
    transition: opacity 0.21s ease-out;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    margin-bottom: 12px;

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      opacity: 0.65;
    }
  }

  svg {
    width: 20px;
    height: 20px;

    * {
      fill: black;
    }
  }
`

const Credit = styled.section`
  position: absolute;
  bottom: 54px;
  left: 21px;

  @media screen and (min-width: 500px) {
    left: 8.3vw;
    bottom: 88px;
  }

  @media screen and (min-width: 800px) {
    bottom: 92px;
  }

  p,
  a {
    color: #bdbdbd;
    font-size: 10px;
    font-size: 0.625rem;

    @media screen and (min-width: 450px) {
      font-size: 13px;
      font-size: 0.8125rem;
    }
  }

  p {
    a:not(.privacy-policy) {
      display: inline-block;
    }
  }
`

const MarathonFooter = (props) => {
  const { menus } = props

  return (
    <FooterContainer>
      <div>
        <img src={medal} alt="marathon gold medal" />
      </div>

      {menus && (
        <div>
          {menus.footer && (
            <nav
              className="footer-menu"
              dangerouslySetInnerHTML={{
                __html: menus.footer,
              }}
            />
          )}

          <Social>
            {menus.footer_secondary && (
              <nav
                dangerouslySetInnerHTML={{
                  __html: menus.footer_secondary,
                }}
              />
            )}

            <a
              className="social-icon"
              href="https://www.facebook.com/DSGPM/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>

            <a
              className="social-icon"
              href="https://www.instagram.com/pghmarathon/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>

            <a
              className="social-icon"
              href="/404"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinIcon />
            </a>

            <a
              className="social-icon"
              href="https://twitter.com/PGHMarathon?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </a>
          </Social>
        </div>
      )}

      <Credit>
        <p>
          <Link
            className="privacy-policy"
            to="/pages/privacy-policy-pittsburgh-marathon"
          >
            Privacy Policy
          </Link>
          &copy; {new Date().getFullYear()} P3R
          <br />
          Website designed & developed by{" "}
          <a
            href="https://www.walltowall.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wall-to-Wall Studios
          </a>
        </p>
      </Credit>
    </FooterContainer>
  )
}

export default MarathonFooter
