import React, { useContext, useEffect } from 'react'
import Block from '../../Block'
import SiteContext from '../../../state/context'

const Races = (props) => {
  const { pageId, site } = props
  const context = useContext(SiteContext)
  const { primary, secondary, tertiary, button, darkText } = props.colors

  useEffect(() => {
    context.getPage(pageId, 'pittsburgh-marathon')
  }, [])

  return (
    <main>
      {Object.keys(context.page).length > 0 && (
        <React.Fragment>
          {context.page.blocks['Hero'] &&
            context.page.blocks['Hero'].map((block) => (
              <Block
                key={block.id}
                data={block}
                colors={{
                  primary,
                  secondary,
                  tertiary,
                  button,
                  darkText,
                }}
              />
            ))}

          {context.page.blocks['List'] &&
            context.page.blocks['List'].map((block) => (
              <Block
                key={block.id}
                data={block}
                site={site}
                colors={{
                  primary,
                  secondary,
                  tertiary,
                  button,
                  darkText,
                }}
              />
            ))}
        </React.Fragment>
      )}
    </main>
  )
}

export default Races
