import React, { useContext } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'

const RaceCollageContainer = styled.section`
  & .race-collage-container {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      'image-cutout-1 lg-image-1 lg-image-1'
      'sm-image-1 lg-image-1 lg-image-1'
      'bk-image bk-image sm-image-2'
      'sm-image-4 lg-image-2 lg-image-2'
      'image-cutout-3 lg-image-2 lg-image-2'
      'image-cutout-4 sm-image-3 .'
      'image-cutout-5-sm image-cutout-2 .';
    ${(props) =>
      props.site === 'pittsburgh-marathon'
        ? `
      grid-gap: 6px;
      padding: 6px;
    `
        : ''}

    @media screen and (min-width: 768px) {
      margin-bottom: 5em;
      position: relative;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-template-areas:
        'image-cutout-1 lg-image-1 lg-image-1 sm-image-2 . image-cutout-3'
        'sm-image-1 lg-image-1 lg-image-1 bk-image lg-image-2 lg-image-2'
        'image-cutout-4 sm-image-3 image-cutout-5-lg sm-image-4 lg-image-2 lg-image-2';
    }

    @media screen and (min-width: 960px) {
      margin-bottom: 8em;
    }

    & .race-collage-bk-image {
      grid-area: bk-image;
    }

    & .race-collage-sm-image-1 {
      grid-area: sm-image-1;
    }

    & .race-collage-sm-image-2 {
      grid-area: sm-image-2;
    }

    & .race-collage-sm-image-3 {
      grid-area: sm-image-3;
    }

    & .race-collage-sm-image-4 {
      grid-area: sm-image-4;
    }

    & .race-collage-lg-image-1 {
      grid-area: lg-image-1;
    }

    & .race-collage-lg-image-2 {
      grid-area: lg-image-2;
    }

    & .race-collage-image-cutout-1 {
      grid-area: image-cutout-1;
    }

    & .race-collage-image-cutout-2 {
      grid-area: image-cutout-2;
    }

    & .race-collage-image-cutout-3 {
      grid-area: image-cutout-3;
    }

    & .race-collage-image-cutout-4 {
      grid-area: image-cutout-4;

      ${(props) =>
        props.site === 'pittsburgh-marathon'
          ? `
        grid-area: bk-image;
      `
          : ''}

      @media screen and (min-width: 768px) {
        ${(props) =>
          props.site === 'pittsburgh-marathon'
            ? `
          grid-area: image-cutout-4;
        `
            : ''}
      }
    }

    & .race-collage-image-cutout-5-sm {
      grid-area: image-cutout-5-sm;
    }

    & .race-collage-image-cutout-5-lg {
      grid-area: image-cutout-5-lg;
    }

    & .race-collage-sm-image-1,
    .race-collage-sm-image-2,
    .race-collage-sm-image-3,
    .race-collage-sm-image-4,
    .race-collage-lg-image-1,
    .race-collage-lg-image-2 {
      height: 100%;

      & > img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    & .race-collage-sm-image-1,
    .race-collage-sm-image-2,
    .race-collage-sm-image-3,
    .race-collage-sm-image-4 {
      & > img {
        ${(props) =>
          props.site === 'pittsburgh-marathon'
            ? `
            border-radius: 14px;
        `
            : ''}
      }
    }

    & .race-collage-lg-image-1,
    .race-collage-lg-image-2 {
      & > img {
        ${(props) =>
          props.site === 'pittsburgh-marathon'
            ? `
            border-radius: 24px;
        `
            : ''}
      }
    }

    & .race-collage-bk-image,
    .race-collage-image-cutout-3,
    .race-collage-image-cutout-4 {
      position: relative;

      & > img {
        width: 50%;
        object-fit: contain;
      }
    }

    & .race-collage-bk-image {
      z-index: -1;

      @media screen and (min-width: 768px) {
        position: static;

        ${(props) =>
          props.site === 'pittsburgh-marathon'
            ? `
            position: relative;
        `
            : ''}
      }

      & > img {
        position: absolute;
        bottom: 0.625rem;
        right: 0.9375rem;
        width: 85%;

        ${(props) =>
          props.site === 'pittsburgh-marathon'
            ? `
          width: 150%;
          max-width: none;
          right: auto;
          left: 0;
          bottom: -190%;
        `
            : ''}

        @media screen and (min-width: 768px) {
          right: 7.5em;
          top: 2em;
          width: 50%;

          ${(props) =>
            props.site === 'pittsburgh-marathon'
              ? `
            width: 360%;
            top: -60%;
            left: -85%;
          `
              : ''}
        }
      }

      & .race-collage-image-cutout-2 {
        & > img {
          position: absolute;
          bottom: 0;
          right: 0;
          transform: rotate(60deg);
          width: 45%;

          @media screen and (min-width: 768px) {
            right: 5em;
            top: 3em;
            transform: rotate(45deg);
            width: 20%;
          }

          @media screen and (min-width: 960px) {
            right: 6em;
          }

          @media screen and (min-width: 1200px) {
            right: 7em;
          }

          @media screen and (min-width: 1440px) {
            right: 8em;
          }
        }
      }

      & .race-collage-image-cutout-5-sm {
        @media screen and (min-width: 768px) {
          display: none;
        }

        & > img {
          position: absolute;
          bottom: 0.3125rem;
          left: 1.25rem;
          width: 20%;
        }
      }
    }

    & .race-collage-image-cutout-1 {
      background-color: #89b7e8;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      ${(props) =>
        props.site === 'pittsburgh-marathon'
          ? `
        border-radius: 14px;;
      `
          : ''}

      & > img {
        width: 100%;
        ${(props) =>
          props.site === 'pittsburgh-marathon'
            ? `
          border-radius: 14px;;
        `
            : ''}
      }

      & > h5 {
        color: #fff;
        font-size: 10px;
        font-size: 0.625rem;
        margin-bottom: 0;
        position: relative;
        z-index: 1;

        @media screen and (min-width: 500px) {
          font-size: 16px;
          font-size: 1rem;
        }

        @media screen and (min-width: 768px) {
          font-size: 10px;
          font-size: 0.625rem;
        }

        @media screen and (min-width: 960px) {
          font-size: 16px;
          font-size: 1rem;
        }
      }
    }

    & .race-collage-image-cutout-3 {
      & > img {
        position: absolute;
        right: -0.5rem;
        top: 0.8125rem;
        transform: rotate(-65deg);
        z-index: -1;

        @media screen and (min-width: 768px) {
          bottom: -1em;
          right: 1em;
          top: auto;
        }
      }
    }

    & .race-collage-image-cutout-4 {
      & > img {
        position: absolute;
        right: -0.9375rem;
        top: 1.5625rem;
        width: 80%;
        z-index: -1;
        transform: rotate(16deg);
        ${(props) =>
          props.site === 'pittsburgh-marathon'
            ? `
          top: 1rem;
          right: -2.25rem;
          width: 40%;
          transform: rotate(0);
        `
            : ''}

        @media screen and (min-width: 768px) {
          right: -1.875rem;
          ${(props) =>
            props.site === 'pittsburgh-marathon'
              ? `
            top: 1.75rem;
            right: -15%;
            width: 80%;
          `
              : ''}
        }
      }
    }

    & .race-collage-image-cutout-5-lg {
      display: none;

      @media screen and (min-width: 768px) {
        display: block;
        position: relative;

        & > img {
          position: absolute;
          bottom: 0.75em;
          left: 1em;
          width: 50%;
        }
      }
    }
  }
`

const RaceCollage = (props) => {
  const context = useContext(SiteContext)
  const { block, site } = props
  const {
    bk_image,
    sm_image_1,
    sm_image_2,
    sm_image_3,
    sm_image_4,
    lg_image_1,
    lg_image_2,
    image_cutout_1,
    image_cutout_2,
    image_cutout_3,
    image_cutout_4,
    image_cutout_5,
    hashtag,
  } = block.settings

  return (
    <RaceCollageContainer site={site}>
      <div className="race-collage-container">
        <div className="race-collage-bk-image">
          {(bk_image || {}).image && (
            <img src={`${context.root}${bk_image.image}`} />
          )}

          <div className="race-collage-image-cutout-2">
            {(image_cutout_2 || {}).image && (
              <img src={`${context.root}${image_cutout_2.image}`} />
            )}
          </div>

          <div className="race-collage-image-cutout-5-sm">
            {(image_cutout_5 || {}).image && (
              <img src={`${context.root}${image_cutout_5.image}`} />
            )}
          </div>
        </div>

        <div className="race-collage-sm-image-1">
          {(sm_image_1 || {}).image && (
            <img src={`${context.root}${sm_image_1.image}`} />
          )}
        </div>

        <div className="race-collage-sm-image-2">
          {(sm_image_2 || {}).image && (
            <img src={`${context.root}${sm_image_2.image}`} />
          )}
        </div>

        <div className="race-collage-sm-image-3">
          {(sm_image_3 || {}).image && (
            <img src={`${context.root}${sm_image_3.image}`} />
          )}
        </div>

        <div className="race-collage-sm-image-4">
          {(sm_image_4 || {}).image && (
            <img src={`${context.root}${sm_image_4.image}`} />
          )}
        </div>

        <div className="race-collage-lg-image-1">
          {(lg_image_1 || {}).image && (
            <img src={`${context.root}${lg_image_1.image}`} />
          )}
        </div>

        <div className="race-collage-lg-image-2">
          {(lg_image_2 || {}).image && (
            <img src={`${context.root}${lg_image_2.image}`} />
          )}
        </div>

        <div className="race-collage-image-cutout-1">
          {(image_cutout_1 || {}).image && (
            <img src={`${context.root}${image_cutout_1.image}`} />
          )}
          {hashtag && <h5>{hashtag}</h5>}
        </div>

        <div className="race-collage-image-cutout-3">
          {(image_cutout_3 || {}).image && (
            <img src={`${context.root}${image_cutout_3.image}`} />
          )}
        </div>

        <div className="race-collage-image-cutout-4">
          {(image_cutout_4 || {}).image && (
            <img src={`${context.root}${image_cutout_4.image}`} />
          )}
        </div>

        <div className="race-collage-image-cutout-5-lg">
          {(image_cutout_5 || {}).image && (
            <img src={`${context.root}${image_cutout_5.image}`} />
          )}
        </div>
      </div>
    </RaceCollageContainer>
  )
}

export default RaceCollage
