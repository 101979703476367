import React, { useContext } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import { DateTime } from 'luxon'
import Swiper from 'react-id-swiper'
import { ReactComponent as ArrowLeft } from '../../images/arrow_left.svg'
import { ReactComponent as ArrowRight } from '../../images/arrow_right.svg'

const RacesCardsContainer = styled.section`
  margin-bottom: 38px;
  position: relative;
  overflow: hidden;

  .swiper-container {
    margin-top: -14px;
    padding: 0 0 72px 21px;

    @media screen and (min-width: 500px) {
      padding-left: 8.3vw;
    }

    @media screen and (min-width: 600px) {
      margin-top: -54px;
    }
  }

  .swiper-slide {
    @media screen and (max-width: 499px) {
      width: auto !important;
      padding-right: 78px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background: none;
    border: 0;
    padding: 0;
    width: 36px;
    height: 36px;
    top: auto;
    margin-top: 0;
    transition: opacity 0.21s ease-out;

    &:hover,
    &:focus {
      opacity: 0.75;
      background: none;
    }

    &::after {
      display: none;
    }

    svg {
      width: 100%;
      height: 100%;

      * {
        ${({ buttons }) => `
          stroke: ${buttons};
        `}
      }
    }

    &.swiper-button-disabled {
      display: none !important;
    }
  }

  .swiper-button-next {
    bottom: 0;
    right: 9.3vw;
  }

  .swiper-button-prev {
    left: auto;
    bottom: 0;
    right: calc(9.3vw + 48px);
  }
`

const RacesCardsHeader = styled.div`
  padding: 24px 21px 22px;
  position: relative;
  color: white;

  @media screen and (min-width: 500px) {
    padding: 24px 8.3vw 22px;
  }

  @media screen and (min-width: 600px) {
    padding-bottom: 62px;
    padding-right: 0;
    width: 75%;
  }

  @media screen and (min-width: 768px) {
    width: 58%;
  }

  ${(props) => `
    background-color: ${props.background};
  `}

  & svg {
    display: none;

    @media screen and (min-width: 600px) {
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
    }
  }

  & h2 {
    margin-bottom: 0;
    font-size: 34px;
    font-size: 2.125rem;

    @media screen and (min-width: 600px) {
      font-size: 62px;
      font-size: 3.875rem;
    }
  }

  & p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    margin-top: 8px;

    @media screen and (min-width: 600px) {
      font-size: 26px;
      font-size: 1.625rem;
    }
  }
`

const RacesCard = styled.a`
  display: block;
  width: 186px;
  text-decoration: none;
  color: black;

  @media screen and (min-width: 500px) {
    width: 334px;
  }

  & figure {
    margin: 0;
  }

  & img {
    display: block;
    max-width: 100%;

    ${(props) => props.site === "pittsburgh-marathon" ? `
      border-radius: 50px;
    ` : ''}
  }

  &:hover,
  &:focus {
    div img {
      opacity: 0.35;
    }
  }
`

const RacesCardDetails = styled.div`
  transform: skew(-30.5deg);
  min-height: 84px;
  display: flex;
  align-items: center;
  padding: 12px 10px 12px 32px;
  margin-top: -44px;
  position: relative;
  left: 28px;
  overflow: hidden;
  max-width: 230px;

  @media screen and (min-width: 500px) {
    max-width: 320px;
    min-height: 156px;
    margin-top: -56px;
    left: 18px;
    padding: 12px 16px 12px 46px;
  }

  @media screen and (min-width: 768px) {
    max-width: 400px;
  }

  @media screen and (min-width: 1024px) {
    min-height: 190px;
    margin-top: -96px;
    padding: 10px 28px 10px 54px;
  }

  ${(props) => `
    background-color: ${props.background};
  `}

  img {
    width: 120%;
    height: 100%;
    max-width: none;
    object-fit: cover;
    transform: skew(30.5deg);
    position: absolute;
    top: 0;
    left: -10%;
    filter: brightness(0) invert(1);
    opacity: 0;
    transition: opacity 0.21s ease-out;
  }

  & > div {
    transform: skew(30.5deg);
  }

  & h3 {
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 14px;
    line-height: 1.1;

    @media screen and (min-width: 500px) {
      font-size: 24px;
      font-size: 1.5rem;
      margin-bottom: 18px;
    }

    @media screen and (min-width: 900px) {
      font-size: 30px;
      font-size: 1.875rem;
    }

    @media screen and (min-width: 1024px) {
      font-size: 38px;
      font-size: 2.375rem;
    }
  }

  & h4 {
    line-height: 1;
    margin-bottom: 16px;
  }

  & p {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 700;
    margin: 2px 0;
    text-transform: uppercase;

    @media screen and (min-width: 500px) {
      font-size: 16px;
      font-size: 1rem;
    }

    @media screen and (min-width: 900px) {
      font-size: 18px;
      font-size: 1.125rem;
    }

    @media screen and (min-width: 1024px) {
      font-size: 20px;
      font-size: 1.25rem;
    }
  }
`

const Prev = () => (
  <button className="swiper-button-prev">
    <ArrowLeft />
  </button>
)

const Next = () => (
  <button className="swiper-button-next">
    <ArrowRight />
  </button>
)

const RacesCards = (props) => {
  const context = useContext(SiteContext)
  const { settings, races } = props.block
  const { colors, site } = props

  const params = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slidesPerView: 'auto',
    renderPrevButton: () => <Prev />,
    renderNextButton: () => <Next />,
  }

  return (
    <RacesCardsContainer buttons={colors.racesBackground || colors.secondary}>
      {settings.heading && settings.copy && (
        <RacesCardsHeader
          background={colors.racesBackground || colors.secondary}
        >
          {settings.heading && <h2>{settings.heading}</h2>}

          {settings.copy && (
            <div
              dangerouslySetInnerHTML={{
                __html: settings.copy.replace(
                  /(\/media)+/g,
                  `${context.root}/media`
                ),
              }}
            />
          )}

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 370">
            <polygon
              points="220 0 0 0 0 370 220 0"
              fill={colors.racesBackground || colors.secondary}
            />
          </svg>
        </RacesCardsHeader>
      )}

      <Swiper {...params}>
        {(races || []).map((item, index) => {
          const race = item.object.race
          const image = item.featured
          const details = (item.details || {}).block
          const logo = item.logo

          return (
            <div
              key={`${race.id}-${index}`}
              style={{
                width: 476,
              }}
            >
              <RacesCard href={`/races/${race.slug}`} site={site}>
                <figure>
                  {image && (
                    <React.Fragment>
                      {image.block.settings.image && (
                        <img
                          src={`${context.root}${image.block.settings.image.image}`}
                          alt={image.block.settings.alt_attribute_description}
                        />
                      )}
                    </React.Fragment>
                  )}
                </figure>

                <RacesCardDetails
                  background={colors.racesCardBackground || colors.primary}
                >
                  {logo && <img src={`${context.root}${logo}`} />}

                  <div>
                    <h3>{race.title}</h3>

                    {details && (
                      <React.Fragment>
                        {details.settings.date && (
                          <React.Fragment>
                            <p>
                              {DateTime.fromFormat(
                                details.settings.date,
                                'MMMM d, yyyy h:mm a'
                              ).toFormat('EEE, LLLL d, yyyy')}
                            </p>

                            <p>
                              {`START @ ${DateTime.fromFormat(
                                details.settings.date,
                                'MMMM d, yyyy h:mm a'
                              ).toFormat('h:mma')}`}
                            </p>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </RacesCardDetails>
              </RacesCard>
            </div>
          )
        })}
      </Swiper>
    </RacesCardsContainer>
  )
}

export { RacesCard, RacesCardDetails }

export default RacesCards
